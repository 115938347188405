import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) {
  return _pushScopeId("data-v-14ad672d"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "vab-app-main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_VabRouterView = _resolveComponent("VabRouterView");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("section", null, [_createVNode(_component_VabRouterView)]), _createCommentVNode(" <vab-footer /> ")]);
}