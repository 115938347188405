/**
 * @description 路由守卫，目前两种模式：all模式与intelligence模式
 */
import { useUserStore } from "@/store/modules/user";
import { useRoutesStore } from "@/store/modules/routes";
import { useSettingsStore } from "@/store/modules/settings";
import VabProgress from "nprogress";
import "nprogress/nprogress.css";
import getPageTitle from "@/utils/pageTitle";
import { toLoginRoute } from "@/utils/routes";
import Layout from "@vab/layouts/index.vue";
import { getMenuList } from "@/api/setting/menu";
import {
  authentication,
  loginInterception,
  routesWhiteList,
  supportVisit,
} from "@/config";
import { Router } from "vue-router";

export function setupPermissions(router: Router) {
  VabProgress.configure({
    easing: "ease",
    speed: 500,
    trickleSpeed: 200,
    showSpinner: false,
  });

  // 动态加载路由
  const handleroutes = async () => {
    let global: any = [
      {
        path: "/da",
        name: "Demo",
        // url:
        component: Layout,
        meta: {
          title: "测试",
          icon: "home-2-line",
        },
        children: [
          {
            path: "demo",
            name: "Demo",
            component: () => import("@/views/demo/index.vue"),
            meta: {
              title: "测试菜单",
              icon: "home-2-line",
              // noClosable: true,
            },
          },
        ],
      },
    ];
    //获取菜单列表数据
    await getMenuList().then((res: any) => {
      global = res.data;
    });
    const urlList: any = [];

    // 第一层
    for (const key in global) {
      //拆分路径
      const path = global[key].path.split("/")[1];
      let obj: any = {
        path: "/" + path,
        name: path
          ? path
              .toLowerCase()
              .replace(/( |^)[a-z]/g, (L: any) => L.toUpperCase())
          : "", //正则转换首字母大写
        meta: {
          title: global[key].title,
          icon: global[key].icon,
        },
      };
      if (
        global[key] &&
        global[key].children &&
        global[key].children.length > 0
      ) {
        obj = {
          ...obj,
          component: Layout,
          children: [],
        };

        //第二层=================================================================================
        const children2: any = global[key].children;
        for (const key2 in children2) {
          //拆分路径
          const path2 = children2[key2].path.split("/")[2];
          let obj2: any = {
            path: path2,
            name: path2
              ? path2
                  .toLowerCase()
                  .replace(/( |^)[a-z]/g, (L: any) => L.toUpperCase())
              : "", //正则转换首字母大写
            meta: {
              title: children2[key2].title,
              icon: children2[key2].icon,
            },
            children: [],
          };
          if (
            children2[key2] &&
            children2[key2].children &&
            children2[key2].children.length > 0
          ) {
            //第三层----------------------------------------------------------------------------
            const children3 = children2[key2].children;
            for (const key3 in children3) {
              //拆分路径
              const path3 = children3[key3].path.split("/")[3];
              let obj3: any = {
                path: path3,
                name: path3
                  ? path3
                      .toLowerCase()
                      .replace(/( |^)[a-z]/g, (L: any) => L.toUpperCase())
                  : "", //正则转换首字母大写
                meta: {
                  title: children3[key3].title,
                  icon: children3[key3].icon,
                },
              };
              obj3 = {
                ...obj3,
                component: () =>
                  import("@/views" + children3[key3].path + "/index.vue"),
              };
              obj2["children"].push(obj3);
            }
            // ----------------------------------------------------------------------------
          } else {
            obj2 = {
              ...obj2,
              component: () =>
                import("@/views" + children2[key2].path + "/index.vue"),
            };
          }
          obj["children"].push(obj2);
        }
        //=================================================================================
      } else {
        obj = {
          ...obj,
          component: () => import("@/views" + global[key].path + "/index.vue"),
        };
      }
      urlList.push(obj);
      // console.log("路由结构=>", urlList);
      // router.addRoute(obj);
    }
    // router.addRoute(urlList);
    // }
  };
  router.beforeEach(async (to: { path: string }, from: any, next: any) => {
    console.log(to, from, next);
    handleroutes();
    const {
      getTheme: { showProgressBar },
    } = useSettingsStore();
    const { routes, setRoutes } = useRoutesStore();
    const { token, getLoginInfo, setVirtualRoles, resetAll } = useUserStore();

    if (showProgressBar) VabProgress.start();

    let hasToken = token;

    if (!loginInterception) hasToken = true;

    if (hasToken) {
      if (routes.length) {
        // 禁止已登录用户返回登录页
        if (to.path === "/login") {
          next({ path: "/" });
          if (showProgressBar) VabProgress.done();
        } else next();
      } else {
        try {
          if (loginInterception) await getLoginInfo();
          // config/setting.config.js loginInterception为false(关闭登录拦截时)时，创建虚拟角色
          else await setVirtualRoles();
          // 根据路由模式获取路由并根据权限过滤
          await setRoutes(authentication);
          next({ ...to, replace: true });
        } catch (err) {
          console.error("vue-admin-beautiful错误拦截:", err);
          await resetAll();
          next(toLoginRoute(to.path));
        }
      }
    } else {
      if (routesWhiteList.includes(to.path)) {
        // 设置游客路由(不需要可以删除)
        if (supportVisit && !routes.length) {
          await setRoutes("visit");
          next({ path: to.path, replace: true });
        } else next();
      } else next(toLoginRoute(to.path));
    }
  });
  router.afterEach((to: any) => {
    document.title = getPageTitle(to.meta.title);
    if (VabProgress.status) VabProgress.done();
  });
}
