import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_menu = _resolveComponent("vab-menu");
  var _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createBlock(_resolveDynamicComponent(_ctx.menuComponent), {
    "item-or-menu": _ctx.item
  }, {
    default: _withCtx(function () {
      return [_ctx.item.children && _ctx.item.children.length ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_ctx.layout === 'horizontal' && _ctx.item.children.length > 18 || _ctx.layout !== 'horizontal' && _ctx.collapse && _ctx.item.children.length > 18 ? (_openBlock(), _createBlock(_component_el_scrollbar, {
        key: 0,
        class: "vab-menu-children-height"
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, function (route) {
            return _openBlock(), _createBlock(_component_vab_menu, {
              key: route.linkUrl,
              item: route
            }, null, 8 /* PROPS */, ["item"]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      })) : (_openBlock(true), _createElementBlock(_Fragment, {
        key: 1
      }, _renderList(_ctx.item.children, function (route) {
        return _openBlock(), _createBlock(_component_vab_menu, {
          key: route.linkUrl,
          item: route
        }, null, 8 /* PROPS */, ["item"]);
      }), 128 /* KEYED_FRAGMENT */))], 64 /* STABLE_FRAGMENT */)) : _createCommentVNode("v-if", true)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["item-or-menu"]);
}