import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) {
  return _pushScopeId("data-v-587b15c8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "avatar-dropdown"
};
var _hoisted_2 = {
  class: "user-name"
};
var _hoisted_3 = {
  class: "hidden-xs-only"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_icon = _resolveComponent("vab-icon");
  var _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  var _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createBlock(_component_el_dropdown, {
    onCommand: $setup.handleCommand,
    onVisibleChange: $setup.handleVisibleChange
  }, {
    dropdown: _withCtx(function () {
      return [_createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_dropdown_item, {
            command: "personalCenter"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_vab_icon, {
                icon: "user-line"
              }), _createElementVNode("span", null, _toDisplayString($setup.translate("个人中心")), 1 /* TEXT */)];
            }),
            _: 1 /* STABLE */
          }), _createVNode(_component_el_dropdown_item, {
            command: "logout"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_vab_icon, {
                icon: "logout-circle-r-line"
              }), _createElementVNode("span", null, _toDisplayString($setup.translate("退出登录")), 1 /* TEXT */)];
            }),
            _: 1 /* STABLE */
          })];
        }),
        _: 1 /* STABLE */
      })];
    }),
    default: _withCtx(function () {
      return [_createElementVNode("span", _hoisted_1, [_createCommentVNode(" <el-avatar class=\"user-avatar\" :src=\"avatar\" /> "), _createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString($setup.username), 1 /* TEXT */), _createVNode(_component_vab_icon, {
        class: _normalizeClass(["vab-dropdown", {
          'vab-dropdown-active': $setup.active
        }]),
        icon: "arrow-down-s-line"
      }, null, 8 /* PROPS */, ["class"])])])];
    }),
    _: 1 /* STABLE */
  });
}