import request from "@/utils/request";
import { encryptedData } from "@/utils/encrypt";
import { loginRSA } from "@/config";

export async function login(data: any) {
  if (loginRSA) {
    data = await encryptedData(data);
  }
  return request({
    url: "/v1/employee/login",
    method: "post",
    data,
  });
}

export async function socialLogin(data: any) {
  if (loginRSA) {
    data = await encryptedData(data);
  }
  return request({
    url: "/socialLogin",
    method: "post",
    data,
  });
}

/**
 *
 * @returns
 */
export function getLoginInfo() {
  return request({
    url: "/v1/employee/getLoginInfo",
    method: "get",
  });
}

/**
 *
 * @returns
 */
export function logout(data?: any) {
  return request({
    url: "v1/employee/logout",
    method: "post",
    data,
  });
}

// export function logout() {
//   return request({
//     url: "/logout",
//     method: "get",
//   });
// }

export function register(data: any) {
  return request({
    url: "/register",
    method: "post",
    data,
  });
}

/**
 * ��ȡ��֤��
 */

export function verifyCodeImage() {
  return request({
    url: "/v1/system/verifyCodeImage",
    method: "get",
  });
}

/**
 * ��ѯְԱ�б�
 * @param {*} params
 */
export function getEmployeeList(params: any) {
  return request({
    url: "/v1/employee/getEmployeeList",
    method: "get",
    params,
  });
}
