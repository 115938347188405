/**
 * @description 异常捕获的状态拦截，请勿修改
 */
import { ErrorLogModuleType } from "/#/store";
import { defineStore } from "pinia";
export const useErrorLogStore = defineStore("errorLog", {
  state: (): ErrorLogModuleType => ({
    errorLogs: [],
  }),
  getters: {
    getErrorLogs: (state: any) => state.errorLogs,
  },
  actions: {
    addErrorLog(errorLog: any) {
      this.errorLogs.push(errorLog);
    },
    clearErrorLog() {
      this.errorLogs.splice(0);
    },
  },
});
