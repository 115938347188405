import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) {
  return _pushScopeId("data-v-21cef8eb"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "vab-theme-setting"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return $setup.theme.showThemeSetting ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [_createCommentVNode(" <li @click=\"handleOpenTheme\">\n      <a>\n        <vab-icon icon=\"brush-2-line\" />\n        <p>{{ translate('主题配置') }}</p>\n      </a>\n    </li>\n    <li @click=\"randomTheme\">\n      <a>\n        <vab-icon icon=\"apps-line\" />\n        <p>{{ translate('随机换肤') }}</p>\n      </a>\n    </li> "), _createCommentVNode(" <li @click=\"buy\">\n      <a>\n        <vab-icon icon=\"shopping-cart-2-line\" />\n        <p>{{ translate('购买源码') }}</p>\n      </a>\n    </li>\n    <li @click=\"getCode\">\n      <a>\n        <vab-icon icon=\"file-copy-line\" />\n        <p>{{ translate('拷贝源码') }}</p>\n      </a>\n    </li>\n    <li @click=\"removeLocalStorage\">\n      <a>\n        <vab-icon icon=\"delete-bin-4-line\" />\n        <p>\n          {{ translate('清理缓存') }}\n        </p>\n      </a>\n    </li> ")])) : _createCommentVNode("v-if", true);
}