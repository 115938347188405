import { AclModuleType } from "/#/store";
import { defineStore } from "pinia";
export const useAclStore = defineStore("acl", {
  state: (): AclModuleType => ({
    admin: false,
    role: [],
    permission: [],
  }),
  getters: {
    getAdmin: (state: any) => state.admin,
    getRole: (state: any) => state.role,
    getPermission: (state: any) => state.permission,
  },
  actions: {
    setFull(admin: boolean) {
      this.admin = admin;
    },
    setRole(role: string[]) {
      this.role = role;
    },
    setPermission(permission: string[]) {
      this.permission = permission;
    },
  },
});
