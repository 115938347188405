import Layout from "@vab/layouts/index.vue";

export default {
  path: "/store",
  name: "StoreManagement",
  component: Layout,
  meta: {
    title: "仓库",
    icon: "user-settings-line",
    // guard: ['Admin'],
  },
  children: [
    // bms页面
    // {
    //   path: 'index',
    //   name: 'index',
    //   component: () => import('@/views/setting/index/index.vue'),
    //   meta: {
    //     title: '首页',
    //     icon: 'flow-chart',
    //   },
    // },
    {
      path: "other",
      name: "other",
      meta: {
        title: "入库库存盘点",
        icon: "remixicon-line",
      },

      children: [
        {
          path: "Instorage",
          name: "Instorage",
          component: () => import("@/views/store/other/Instorage/index.vue"),
          meta: {
            title: "商品入库",
            icon: "flow-chart",
          },
        },
        {
          path: "Stocktaking",
          name: "Stocktaking",
          component: () => import("@/views/store/other/Stocktaking/index.vue"),
          meta: {
            title: "盘点库存",
            icon: "flow-chart",
          },
        },
        {
          path: "Stock",
          name: "Stock",
          component: () => import("@/views/store/other/Stock/index.vue"),
          meta: {
            title: "库存预警",
            icon: "flow-chart",
          },
        },
        {
          path: "operationLog",
          name: "operationLog",
          component: () => import("@/views/store/other/operationLog/index.vue"),
          meta: {
            title: "操作日志",
            icon: "flow-chart",
          },
        },
        {
          path: "InventoryList",
          name: "InventoryList",
          component: () =>
            import("@/views/store/other/InventoryList/index.vue"),
          meta: {
            title: "库存流水",
            icon: "flow-chart",
          },
        },
        {
          path: "customerOrder",
          name: "customerOrder",
          component: () => import("@/views/store/customerOrder/index.vue"),
          meta: {
            title: "客户订单",
            icon: "flow-chart",
          },
        },
      ],
    },
    {
      path: "Stocktaking/InventoryPrint",
      name: "Stocktaking/InventoryPrint",
      component: () =>
        import("@/views/store/other/Stocktaking/InventoryPrint.vue"),
      meta: {
        title: "未盘点打印",
        icon: "flow-chart",
      },
    },
    {
      path: "Stocktaking/ConfirmPrint",
      name: "Stocktaking/ConfirmPrint",
      component: () =>
        import("@/views/store/other/Stocktaking/ConfirmPrint.vue"),
      meta: {
        title: "未盘点打印",
        icon: "flow-chart",
      },
    },
    {
      path: "customerOrder",
      name: "customerOrder",
      component: () => import("@/views/store/customerOrder/index.vue"),
      meta: {
        title: "客户订单",
        icon: "flow-chart",
      },
    },
    {
      path: "customerOrder/print",
      name: "customerOrder/print",
      component: () =>
        import(
          "@/views/store/customerOrder/components/PageMain/components/print.vue"
        ),
      meta: {
        title: "发货清单打印",
        icon: "flow-chart",
      },
    },
    {
      path: "customerOrder/Check",
      name: "customerOrder/Check",
      component: () =>
        import(
          "@/views/store/customerOrder/components/PageMain/components/Check.vue"
        ),
      meta: {
        title: "发货清单打印",
        icon: "flow-chart",
      },
    },
    {
      path: "customerOrder/EMSfourbatchPrint",
      name: "customerOrder/EMSfourbatchPrint",
      component: () =>
        import(
          "@/views/store/customerOrder/components/PageMain/components/EMSfourbatchPrint.vue"
        ),
      meta: {
        title: "EMS电子面单打印（新）",
        icon: "flow-chart",
      },
    },
    {
      path: "customerOrder/SFbatchPrint",
      name: "customerOrder/SFbatchPrint",
      component: () =>
        import(
          "@/views/store/customerOrder/components/PageMain/components/SFbatchPrint.vue"
        ),
      meta: {
        title: "（新）顺丰批量打印",
        icon: "flow-chart",
      },
    },
    {
      path: "customerOrder/jdPrint",
      name: "customerOrder/jdPrint",
      component: () =>
        import(
          "@/views/store/customerOrder/components/PageMain/components/jdPrint.vue"
        ),
      meta: {
        title: "京东打印",
        icon: "flow-chart",
      },
    },
    {
      path: "customerOrder/YTbatchPrint",
      name: "customerOrder/YTbatchPrint",
      component: () =>
        import(
          "@/views/store/customerOrder/components/PageMain/components/YTbatchPrint.vue"
        ),
      meta: {
        title: "（新）一联-圆通批量打印",
        icon: "flow-chart",
      },
    },
    {
      path: "customerOrder/oldYTbatchPrint",
      name: "customerOrder/oldYTbatchPrint",
      component: () =>
        import(
          "@/views/store/customerOrder/components/PageMain/components/oldYTbatchPrint.vue"
        ),
      meta: {
        title: "（新）二联-圆通批量打印",
        icon: "flow-chart",
      },
    },
    {
      path: "bmsTrajectory",
      name: "bmsTrajectory",
      component: () => import("@/views/store/bmsTrajectory/index.vue"),
      meta: {
        title: "(BMS)快递轨迹",
        icon: "flow-chart",
      },
    },
    {
      path: "afterSales/index",
      name: "afterSales/index",
      component: () => import("@/views/store/afterSales/index.vue"),
      meta: {
        title: "售后列表",
        icon: "flow-chart",
      },
    },
    {
      path: "admin/list",
      name: "admin/list",
      component: () => import("@/views/store/admin/index.vue"),
      meta: {
        title: "达梦列表",
        icon: "flow-chart",
      },
    },
    {
      path: "admin/print",
      name: "admin/print",
      // component: () =>
      //   import(""),
      component: () =>
        import("@/views/store/admin/components/PageMain/components/print.vue"),
      meta: {
        title: "发货清单打印",
        icon: "flow-chart",
      },
    },
    {
      path: "admin/SFbatchPrint",
      name: "admin/SFbatchPrint",
      // component: () =>
      //   import(""),
      component: () =>
        import("@/views/store/admin/components/PageMain/SFbatchPrint.vue"),
      meta: {
        title: "（新）顺丰批量打印",
        icon: "flow-chart",
      },
    },
    {
      path: "sourcePharmacist",
      name: "sourcePharmacist",
      component: () => import("@/views/store/sourcePharmacist/index.vue"),
      meta: {
        title: "源药师订单",
        icon: "flow-chart",
      },
    },
    {
      path: "admin/print",
      name: "admin/print",
      component: () =>
        import("@/views/store/admin/components/PageMain/components/print.vue"),
      meta: {
        title: "发货清单打印",
        icon: "flow-chart",
      },
    },
    {
      path: "parcelCenter",
      name: "parcelCenter",
      component: () => import("@/views/store/parcelCenter/index.vue"),
      meta: {
        title: "包裹中心",
        icon: "flow-chart",
      },
    },
  ],
};
