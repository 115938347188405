import Layout from "@vab/layouts/index.vue";

export default {
  path: "/goods",
  name: "Goods",
  component: Layout,
  meta: {
    title: "商品",
    icon: "home-2-line",
  },
  children: [
    {
      path: "admin",
      name: "admin",
      meta: {
        title: "商品管理",
        icon: "remixicon-line",
      },
      // children: [
      //   {
      //     path: "create",
      //     name: "Create",
      //     component: () => import("@/views/goods/admin/create/index.vue"),
      //     meta: {
      //       title: "新增商品",
      //       icon: "home-2-line",
      //     },
      //   },
      //   {
      //     path: "list",
      //     name: "List",
      //     component: () => import("@/views/goods/admin/list/index.vue"),
      //     meta: {
      //       title: "商品列表",
      //       icon: "home-2-line",
      //     },
      //   },
      // ],
    },
    {
      path: "setting",
      name: "setting",
      meta: {
        title: "商品配置",
        icon: "remixicon-line",
      },
      children: [
        {
          path: "brand",
          name: "brand",
          component: () => import("@/views/goods/setting/brand/index.vue"),
          meta: {
            title: "商品品牌",
            icon: "home-2-line",
          },
        },
        {
          path: "type",
          name: "type",
          component: () => import("@/views/goods/setting/type/index.vue"),
          meta: {
            title: "商品模型",
            icon: "home-2-line",
          },
        },
        {
          path: "category",
          name: "category",
          component: () => import("@/views/goods/setting/category/index.vue"),
          meta: {
            title: "商品分类",
            icon: "home-2-line",
          },
        },
      ],
    },
  ],
};
