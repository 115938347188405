import Layout from "@vab/layouts/index.vue";

export default {
  path: "/setting",
  name: "SettingManagement",
  component: Layout,
  meta: {
    title: "设置",
    icon: "user-settings-line",
    // guard: ['Admin'],
  },
  children: [
    // bms页面
    {
      path: "index",
      name: "index",
      component: () => import("@/views/setting/index/index.vue"),
      meta: {
        title: "设置首页",
        icon: "flow-chart",
      },
    },
    {
      path: "auth",
      name: "auth",
      meta: {
        title: "职员",
        icon: "remixicon-line",
      },

      children: [
        {
          path: "client",
          name: "client",
          component: () => import("@/views/setting/auth/client/index.vue"),
          meta: {
            title: "职员管理",
            icon: "flow-chart",
          },
        },
        {
          path: "userIp",
          name: "userIp",
          component: () => import("@/views/setting/auth/userlp/index.vue"),
          meta: {
            title: "账号在线",
            icon: "flow-chart",
          },
        },
        {
          path: "usergroup",
          name: "usergroup",
          component: () => import("@/views/setting/auth/usergroup/index.vue"),
          meta: {
            title: "职员组",
            icon: "flow-chart",
          },
        },
        {
          path: "qualityList",
          name: "qualityList",
          component: () => import("@/views/setting/auth/qualityList/index.vue"),
          meta: {
            title: "审核配置",
            icon: "flow-chart",
          },
        },
        {
          path: "conversation",
          name: "conversation",
          component: () =>
            import("@/views/setting/auth/conversation/index.vue"),
          meta: {
            title: "录音审核员列表",
            icon: "flow-chart",
          },
        },
        {
          path: "reviewerList",
          name: "reviewerList",
          component: () =>
            import("@/views/setting/auth/reviewerList/index.vue"),
          meta: {
            title: "审单员列表",
            icon: "flow-chart",
          },
        },
        {
          path: "department",
          name: "department",
          component: () => import("@/views/setting/auth/department/index.vue"),
          meta: {
            title: "组织",
            icon: "flow-chart",
          },
        },
        {
          path: "binding",
          name: "binding",
          component: () => import("@/views/setting/auth/binding/index.vue"),
          meta: {
            title: "坐席绑定记录",
            icon: "flow-chart",
          },
        },
        {
          path: "customerService",
          name: "customerService",
          component: () =>
            import("@/views/setting/auth/customerService/index.vue"),
          meta: {
            title: "坐席",
            icon: "flow-chart",
          },
        },
        {
          path: "group",
          name: "group",
          component: () => import("@/views/setting/auth/group/index.vue"),
          meta: {
            title: "角色管理",
            icon: "flow-chart",
          },
        },
        {
          path: "menu",
          name: "menu",
          component: () => import("@/views/setting/auth/menu/index.vue"),
          meta: {
            title: "菜单管理",
            icon: "flow-chart",
          },
        },
        {
          path: "rule",
          name: "rule",
          component: () => import("@/views/setting/auth/rule/index.vue"),
          meta: {
            title: "功能权限",
            icon: "flow-chart",
          },
        },
        {
          path: "data",
          name: "data",
          component: () => import("@/views/setting/auth/data/index.vue"),
          meta: {
            title: "数据权限",
            icon: "flow-chart",
          },
        },
        {
          path: "management",
          name: "management",
          component: () => import("@/views/setting/auth/management/index.vue"),
          meta: {
            title: "实体权限",
            icon: "flow-chart",
          },
        },
        // 框架原来示例页面
        {
          path: "personalCenter",
          name: "PersonalCenter",
          component: () => import("@/views/setting/personalCenter/index.vue"),
          meta: {
            title: "个人中心",
            icon: "map-pin-user-line",
          },
        },
        {
          path: "userManagement",
          name: "UserManagement",
          component: () => import("@/views/setting/userManagement/index.vue"),
          meta: {
            title: "用户管理",
            icon: "user-3-line",
          },
        },
        {
          path: "roleManagement",
          name: "RoleManagement",
          component: () => import("@/views/setting/roleManagement/index.vue"),
          meta: {
            title: "角色管理",
            icon: "admin-line",
          },
        },
        {
          path: "departmentManagement",
          name: "DepartmentManagement",
          component: () =>
            import("@/views/setting/departmentManagement/index.vue"),
          meta: {
            title: "部门管理",
            icon: "group-line",
          },
        },
        {
          path: "menuManagement",
          name: "MenuManagement",
          component: () => import("@/views/setting/menuManagement/index.vue"),
          meta: {
            title: "菜单管理",
            icon: "menu-2-fill",
          },
        },
        {
          path: "dictionaryManagement",
          name: "DictionaryManagement",
          component: () =>
            import("@/views/setting/dictionaryManagement/index.vue"),
          meta: {
            title: "字典管理",
            icon: "book-2-line",
            dot: true,
          },
        },
        {
          path: "taskManagement",
          name: "TaskManagement",
          component: () => import("@/views/setting/taskManagement/index.vue"),
          meta: {
            title: "任务管理",
            icon: "task-line",
            badge: "New",
          },
        },
        {
          path: "systemLog",
          name: "SystemLog",
          component: () => import("@/views/setting/systemLog/index.vue"),
          meta: {
            title: "系统日志",
            icon: "file-shield-2-line",
          },
        },
      ],
    },
    {
      path: "security",
      name: "security",
      meta: {
        title: "安全",
        icon: "remixicon-line",
      },
      children: [
        {
          path: "currentIpData",
          name: "currentIpData",
          component: () =>
            import("@/views/setting/security/currentIpData/index.vue"),
          meta: {
            title: "当前Ip数据",
            icon: "flow-chart",
          },
        },
        {
          path: "LiftOreder",
          name: "LiftOreder",
          component: () =>
            import("@/views/setting/security/LiftOreder/index.vue"),
          meta: {
            title: "当前抬单比例",
            icon: "flow-chart",
          },
        },
        {
          path: "DDNSList",
          name: "DDNSList",
          component: () =>
            import("@/views/setting/security/DDNSList/index.vue"),
          meta: {
            title: "DDNS白名单",
            icon: "flow-chart",
          },
        },
      ],
    },
    {
      path: "systemSetting",
      name: "systemSettingfinance",
      component: () => import("@/views/setting/systemSetting/index.vue"),
      meta: {
        title: "系统设置",
        icon: "flow-chart",
      },
    },
    {
      path: "systemSetting",
      name: "systemSetting",
      component: () => import("@/views/setting/systemSetting/index.vue"),
      meta: {
        title: "安全支付系统",
        icon: "remixicon-line",
      },
      children: [
        {
          path: "multiMerchant",
          name: "MultiMerchant",
          component: () =>
            import("@/views/setting/systemSetting/multiMerchant/index.vue"),
          meta: {
            title: "多商户配置",
            icon: "flow-chart",
          },
        },
      ],
    },
    {
      path: "payment",
      name: "payment",
      meta: {
        title: "支付系统",
        icon: "remixicon-line",
      },
      children: [
        {
          path: "log",
          name: "Log",
          component: () => import("@/views/setting/payment/log/index.vue"),
          meta: {
            title: "支付日志",
            icon: "flow-chart",
          },
        },
        {
          path: "config",
          name: "Config",
          component: () => import("@/views/setting/payment/config/index.vue"),
          meta: {
            title: "支付配置",
            icon: "flow-chart",
          },
        },
      ],
    },
    {
      path: "logistics",
      name: "logistics",
      meta: {
        title: "物流管控",
        icon: "remixicon-line",
      },
      children: [
        {
          path: "company",
          name: "Company",
          component: () =>
            import("@/views/setting/logistics/company/index.vue"),
          meta: {
            title: "快递公司",
            icon: "flow-chart",
          },
        },
        {
          path: "delivery",
          name: "Delivery",
          component: () =>
            import("@/views/setting/logistics/delivery/index.vue"),
          meta: {
            title: "配送方式",
            icon: "flow-chart",
          },
        },
        {
          path: "blacklist",
          name: "Blacklist",
          component: () =>
            import("@/views/setting/logistics/blacklist/index.vue"),
          meta: {
            title: "敏感地址",
            icon: "flow-chart",
          },
        },
        {
          path: "region",
          name: "region",
          component: () => import("@/views/setting/logistics/region/index.vue"),
          meta: {
            title: "区域管理",
            icon: "flow-chart",
          },
        },
        {
          path: "/",
          name: "delivery-area",
          component: () =>
            import("@/views/setting/logistics/delivery-area/index.vue"),
          meta: {
            title: "配送区域",
            icon: "flow-chart",
          },
        },
        // {
        //   path: "totalAmount",
        //   name: "TotalAmount",
        //   component: () =>
        //     import("@/views/setting/logistics/totalAmount/index.vue"),
        //   meta: {
        //     title: "客户总订单限额",
        //     icon: "flow-chart",
        //   },
        // },
      ],
    },
    {
      path: "config",
      name: "config",
      meta: {
        title: "配置",
        icon: "remixicon-line",
      },
      children: [
        {
          path: "configExpress",
          name: "ConfigExpress",
          component: () =>
            import("@/views/setting/config/configExpress/index.vue"),
          meta: {
            title: "快递配置",
            icon: "flow-chart",
          },
        },
        {
          path: "pollination",
          name: "pollination",
          component: () =>
            import("@/views/setting/config/pollination/index.vue"),
          meta: {
            title: "报粉配置",
            icon: "flow-chart",
          },
        },
      ],
    },
    {
      path: "storeset",
      name: "storeset",
      meta: {
        title: "校验",
        icon: "remixicon-line",
      },
      children: [
        {
          path: "check",
          name: "Check",
          component: () => import("@/views/setting/storeset/check/index.vue"),
          meta: {
            title: "校验发货设置",
            icon: "flow-chart",
          },
        },
      ],
    },
    {
      path: "orderRule",
      name: "OrderRule",
      component: () => import("@/views/setting/orderRule/index.vue"),
      meta: {
        title: "下单规则",
        icon: "flow-chart",
      },
    },
    {
      path: "baiduCustomer",
      name: "BaiduCustomer",
      component: () => import("@/views/setting/baiduCustomer/index.vue"),
      meta: {
        title: "百度客户",
        icon: "flow-chart",
      },
    },
    {
      path: "admin",
      name: "Admin",
      meta: {
        title: "运营人员",
        icon: "remixicon-line",
      },
      children: [
        {
          path: "membership",
          name: "Membership",
          component: () => import("@/views/setting/admin/membership/index.vue"),
          meta: {
            title: "会员管理",
            icon: "flow-chart",
          },
        },
        {
          path: "member",
          name: "Member",
          component: () => import("@/views/setting/admin/member/index.vue"),
          meta: {
            title: "管理人员",
            icon: "flow-chart",
          },
        },
        {
          path: "action",
          name: "Action",
          component: () => import("@/views/setting/admin/action/index.vue"),
          meta: {
            title: "操作日志",
            icon: "flow-chart",
          },
        },
      ],
    },
    {
      path: "phone",
      name: "Phone",
      meta: {
        title: "配置",
        icon: "remixicon-line",
      },
      children: [
        {
          path: "configuration",
          name: "Configuration",
          component: () =>
            import("@/views/setting/phone/configuration/index.vue"),
          meta: {
            title: "外呼次数限制",
            icon: "flow-chart",
          },
        },
      ],
    },
  ],
};
