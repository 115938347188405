import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) {
  return _pushScopeId("data-v-5c6e3810"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_menu_item = _resolveComponent("el-menu-item");
  return _openBlock(), _createBlock(_component_el_menu_item, {
    index: $props.itemOrMenu.path,
    onClick: $setup.handleLink
  }, {
    default: _withCtx(function () {
      return [$setup.translate($props.itemOrMenu.icon) ? (_openBlock(), _createElementBlock("i", {
        key: 0,
        class: _normalizeClass('iconfont icon' + $setup.translate($props.itemOrMenu.icon))
      }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("span", {
        title: $setup.translate($props.itemOrMenu.title)
      }, _toDisplayString($setup.translate($props.itemOrMenu.title)), 9 /* TEXT, PROPS */, _hoisted_1), _createCommentVNode(" <el-tag v-if=\"itemOrMenu.badge\" effect=\"dark\" type=\"danger\">\n      {{ itemOrMenu.badge }}\n    </el-tag>\n    <span v-if=\"itemOrMenu.dot\" class=\"vab-dot vab-dot-error\">\n      <span />\n    </span> ")];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["index"]);
}