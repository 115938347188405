import { createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue";
var _hoisted_1 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_sub_menu = _resolveComponent("el-sub-menu");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createCommentVNode(" {{ itemOrMenu }} "), _createVNode(_component_el_sub_menu, {
    index: $props.itemOrMenu.path,
    teleported: ""
  }, {
    title: _withCtx(function () {
      return [$setup.translate($props.itemOrMenu.icon) ? (_openBlock(), _createElementBlock("i", {
        key: 0,
        class: _normalizeClass('iconfont icon' + $setup.translate($props.itemOrMenu.icon))
      }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createElementVNode("span", {
        title: $setup.translate($props.itemOrMenu.title)
      }, _toDisplayString($setup.translate($props.itemOrMenu.title)), 9 /* TEXT, PROPS */, _hoisted_1)];
    }),
    default: _withCtx(function () {
      return [_renderSlot(_ctx.$slots, "default")];
    }),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["index"])], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */);
}