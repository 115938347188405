import Layout from "@vab/layouts/index.vue";

export default {
  path: "/newSprint",
  name: "NewSprint",
  component: Layout,
  meta: {
    title: "报粉",
    icon: "home-2-line",
  },
  children: [
    {
      path: "pollingStatistics",
      name: "pollingStatistics",
      meta: {
        title: "报粉统计",
        icon: "remixicon-line",
      },
      children: [
        {
          path: "myPollination",
          name: "myPollination",
          component: () =>
            import(
              "@/views/newSprint/pollingStatistics/myPollination/index.vue"
            ),
          meta: {
            title: "我的报粉",
            icon: "home-2-line",
            // noClosable: true,
          },
        },
        {
          path: "departmentalFanReporting",
          name: "departmentalFanReporting",
          component: () =>
            import(
              "@/views/newSprint/pollingStatistics/departmentalFanReporting/index.vue"
            ),
          meta: {
            title: "部门报粉",
            icon: "home-2-line",
            // noClosable: true,
          },
        },
        {
          path: "channelFollowers",
          name: "channelFollowers",
          component: () =>
            import(
              "@/views/newSprint/pollingStatistics/channelFollowers/index.vue"
            ),
          meta: {
            title: "渠道配置",
            icon: "home-2-line",
          },
        },
        {
          path: "applicationReview",
          name: "applicationReview",
          component: () =>
            import(
              "@/views/newSprint/pollingStatistics/applicationReview/index.vue"
            ),
          meta: {
            title: "Mac绑定日志",
            icon: "home-2-line",
          },
        },
      ],
    },
    {
      path: "configuration",
      name: "configuration",
      meta: {
        title: "报粉配置",
        icon: "remixicon-line",
      },
      children: [
        {
          path: "weChatBinding",
          name: "weChatBinding",
          component: () =>
            import("@/views/newSprint/configuration/weChatBinding/index.vue"),
          meta: {
            title: "微信绑定",
            icon: "home-2-line",
          },
        },
      ],
    },
    {
      path: "weChatStatistics",
      name: "weChatStatistics",
      meta: {
        title: "微信统计",
        icon: "remixicon-line",
      },
      children: [
        {
          path: "powderAdditionStatistics",
          name: "powderAdditionStatistics",
          component: () =>
            import(
              "@/views/newSprint/weChatStatistics/powderAdditionStatistics/index.vue"
            ),
          meta: {
            title: "加粉统计",
            icon: "home-2-line",
          },
        },
      ],
    },
  ],
};
