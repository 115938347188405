import { __assign } from "tslib";
import { isJson } from "@/utils/validate";
import { logo as _logo, title as _title, i18n, layout, themeName, background, columnStyle, fixedHeader, foldSidebar, menuWidth, showProgressBar, showTabs, showTabsIcon, showLanguage, showRefresh, showSearch, showTheme, showNotice, showFullScreen, showThemeSetting, showPageTransition, showLock, tabsBarStyle } from "@/config";
var defaultTheme = {
  layout: layout,
  themeName: themeName,
  background: background,
  columnStyle: columnStyle,
  fixedHeader: fixedHeader,
  foldSidebar: foldSidebar,
  menuWidth: menuWidth,
  showProgressBar: showProgressBar,
  showTabs: showTabs,
  showTabsIcon: showTabsIcon,
  showLanguage: showLanguage,
  showRefresh: showRefresh,
  showSearch: showSearch,
  showTheme: showTheme,
  showNotice: showNotice,
  showFullScreen: showFullScreen,
  showThemeSetting: showThemeSetting,
  showPageTransition: showPageTransition,
  showLock: showLock,
  tabsBarStyle: tabsBarStyle
};
var getLocalStorage = function (key) {
  var value = localStorage.getItem(key);
  return value && isJson(value) ? JSON.parse(value) : false;
};
var theme = getLocalStorage("theme") || __assign({}, defaultTheme);
var _a = getLocalStorage("collapse").collapse,
  collapse = _a === void 0 ? foldSidebar : _a;
var _b = getLocalStorage("language").language,
  language = _b === void 0 ? i18n : _b;
var _c = getLocalStorage("lock").lock,
  lock = _c === void 0 ? false : _c;
var _d = getLocalStorage("logo").logo,
  logo = _d === void 0 ? _logo : _d;
var _e = getLocalStorage("title").title,
  title = _e === void 0 ? _title : _e;
import { defineStore } from "pinia";
export var useSettingsStore = defineStore("settings", {
  state: function () {
    return {
      theme: theme,
      device: "desktop",
      collapse: collapse,
      language: language,
      lock: lock,
      logo: logo,
      title: title,
      echartsGraphic1: ["#3ED572", "#399efd"],
      echartsGraphic2: ["#399efd", "#8cc8ff"]
    };
  },
  getters: {
    getTheme: function (state) {
      return state.theme;
    },
    getDevice: function (state) {
      return state.device;
    },
    getCollapse: function (state) {
      return state.collapse;
    },
    getLanguage: function (state) {
      return state.language;
    },
    getLock: function (state) {
      return state.lock;
    },
    getLogo: function (state) {
      return state.logo;
    },
    getTitle: function (state) {
      return state.title;
    }
  },
  actions: {
    updateState: function (obj) {
      var _this = this;
      Object.getOwnPropertyNames(obj).forEach(function (key) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        _this[key] = obj[key];
        localStorage.setItem(key, typeof obj[key] == "string" ? "{\"".concat(key, "\":\"").concat(obj[key], "\"}") : "{\"".concat(key, "\":").concat(obj[key], "}"));
      });
    },
    saveTheme: function () {
      localStorage.setItem("theme", JSON.stringify(this.theme));
    },
    resetTheme: function () {
      this.theme = __assign({}, defaultTheme);
      localStorage.removeItem("theme");
      this.updateTheme();
    },
    updateTheme: function () {
      var index = this.theme.themeName.indexOf("-");
      var themeName = this.theme.themeName.substring(0, index) || "blue";
      var variables = require("@vab/styles/variables/vab-".concat(themeName, "-variables.module.scss"));
      if (variables.default) variables = variables.default;
      Object.keys(variables).forEach(function (key) {
        if (key.startsWith("vab-")) {
          useCssVar(key.replace("vab-", "--el-"), ref(null)).value = variables[key];
        }
      });
      if (this.theme.menuWidth && this.theme.menuWidth.endsWith("px")) useCssVar("--el-left-menu-width", ref(null)).value = this.theme.menuWidth;else useCssVar("--el-left-menu-width", ref(null)).value = "266px";
      this.echartsGraphic1 = [variables["vab-color-transition"], variables["vab-color-primary"]];
      this.echartsGraphic2 = [variables["vab-color-primary-light-5"], variables["vab-color-primary"]];
      var menuBackground = this.theme.themeName.split("-")[1] || this.theme.themeName;
      document.getElementsByTagName("body")[0].className = "vab-theme-".concat(menuBackground);
      if (this.theme.background !== "none") {
        document.getElementsByTagName("body")[0].classList.add(this.theme.background);
      }
    },
    toggleCollapse: function () {
      this.collapse = !this.collapse;
      localStorage.setItem("collapse", "{\"collapse\":".concat(this.collapse, "}"));
    },
    toggleDevice: function (device) {
      this.updateState({
        device: device
      });
    },
    openSideBar: function () {
      this.updateState({
        collapse: false
      });
    },
    foldSideBar: function () {
      this.updateState({
        collapse: true
      });
    },
    changeLanguage: function (language) {
      this.updateState({
        language: language
      });
    },
    handleLock: function () {
      this.updateState({
        lock: true
      });
    },
    handleUnLock: function () {
      this.updateState({
        lock: false
      });
    },
    changeLogo: function (logo) {
      this.updateState({
        logo: logo
      });
    },
    changeTitle: function (title) {
      this.updateState({
        title: title
      });
    }
  }
});