import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) {
  return _pushScopeId("data-v-3dc3a550"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "vab-header"
};
var _hoisted_2 = {
  class: "vab-main"
};
var _hoisted_3 = {
  class: "right-panel"
};
var _hoisted_4 = {
  key: 0
};
var _hoisted_5 = {
  class: "my-menu"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_logo = _resolveComponent("vab-logo");
  var _component_el_col = _resolveComponent("el-col");
  var _component_vab_menu = _resolveComponent("vab-menu");
  var _component_el_menu = _resolveComponent("el-menu");
  var _component_vab_error_log = _resolveComponent("vab-error-log");
  var _component_vab_lock = _resolveComponent("vab-lock");
  var _component_vab_search = _resolveComponent("vab-search");
  var _component_vab_notice = _resolveComponent("vab-notice");
  var _component_vab_full_screen = _resolveComponent("vab-full-screen");
  var _component_vab_language = _resolveComponent("vab-language");
  var _component_vab_theme = _resolveComponent("vab-theme");
  var _component_vab_refresh = _resolveComponent("vab-refresh");
  var _component_vab_avatar = _resolveComponent("vab-avatar");
  var _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, {
    gutter: 20
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_col, {
        span: 4
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_vab_logo)];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_col, {
        span: 20
      }, {
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_3, [!$setup.handleRoutes ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($setup.handleRoutes), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createElementVNode("div", _hoisted_5, ['horizontal' === $props.layout ? (_openBlock(), _createBlock(_component_el_menu, {
            key: 0,
            "active-text-color": $setup.variables['menu-color-active'],
            "background-color": $setup.variables['menu-background'],
            "default-active": $setup.activeMenu.data,
            "menu-trigger": "hover",
            mode: "horizontal",
            style: {
              "width": "100%"
            },
            teleported: "",
            "text-color": $setup.variables['menu-color']
          }, {
            default: _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, function (item, index) {
                return _openBlock(), _createElementBlock(_Fragment, null, [item ? (_openBlock(), _createBlock(_component_vab_menu, {
                  key: index + item['title'],
                  item: item,
                  layout: $props.layout
                }, null, 8 /* PROPS */, ["item", "layout"])) : _createCommentVNode("v-if", true)], 64 /* STABLE_FRAGMENT */);
              }), 256 /* UNKEYED_FRAGMENT */))];
            }),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["active-text-color", "background-color", "default-active", "text-color"])) : _createCommentVNode("v-if", true)]), _createVNode(_component_vab_error_log), _createVNode(_component_vab_lock), _createVNode(_component_vab_search), _createVNode(_component_vab_notice), _createVNode(_component_vab_full_screen), _createVNode(_component_vab_language), _createVNode(_component_vab_theme), _createVNode(_component_vab_refresh), _createVNode(_component_vab_avatar)])];
        }),
        _: 1 /* STABLE */
      })];
    }),
    _: 1 /* STABLE */
  })])]);
}