import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) {
  return _pushScopeId("data-v-79b3ced0"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a;
  var _component_vab_logo = _resolveComponent("vab-logo");
  var _component_el_tab_pane = _resolveComponent("el-tab-pane");
  var _component_el_tabs = _resolveComponent("el-tabs");
  var _component_el_divider = _resolveComponent("el-divider");
  var _component_vab_menu = _resolveComponent("vab-menu");
  var _component_el_menu = _resolveComponent("el-menu");
  var _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createBlock(_component_el_scrollbar, {
    class: _normalizeClass(["vab-column-bar-container", (_a = {
      'is-collapse': $setup.collapse
    }, _a['vab-column-bar-container-' + $setup.theme.columnStyle] = true, _a)])
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_vab_logo), !$setup.handleRoutes ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($setup.handleRoutes), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createVNode(_component_el_tabs, {
        modelValue: $setup.tab.data,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return $setup.tab.data = $event;
        }),
        "tab-position": "left",
        onTabClick: $setup.handleTabClick
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, function (item, index) {
            return _openBlock(), _createBlock(_component_el_tab_pane, {
              key: index + item.title,
              name: item.title
            }, {
              label: _withCtx(function () {
                var _a;
                return [_createElementVNode("div", {
                  class: _normalizeClass(["vab-column-grid", (_a = {}, _a['vab-column-grid-' + $setup.theme.columnStyle] = true, _a)]),
                  title: $setup.translate(item.title)
                }, [_createElementVNode("div", null, [$setup.translate(item.icon) ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: _normalizeClass('iconfont icon' + $setup.translate(item.icon))
                }, null, 2 /* CLASS */)) : _createCommentVNode("v-if", true), _createCommentVNode(" <span>{{ translate(item.title) }}</span> ")])], 10 /* CLASS, PROPS */, _hoisted_2)];
              }),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue"]), _createVNode(_component_el_menu, {
        "background-color": $setup.variables['column-second-menu-background'],
        "default-active": $setup.activeMenu.data,
        "default-openeds": $setup.defaultOpeneds,
        mode: "vertical",
        "unique-opened": $setup.uniqueOpened
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_divider, null, {
            default: _withCtx(function () {
              return [_createTextVNode(_toDisplayString($setup.translate($setup.tabMenu ? $setup.tabMenu.title : "")), 1 /* TEXT */)];
            }),
            _: 1 /* STABLE */
          }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.children, function (item, index) {
            return _openBlock(), _createBlock(_component_vab_menu, {
              key: index,
              item: item
            }, null, 8 /* PROPS */, ["item"]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["background-color", "default-active", "default-openeds", "unique-opened"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]);
}