import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) {
  return _pushScopeId("data-v-7f689722"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_logo = _resolveComponent("vab-logo");
  var _component_vab_menu = _resolveComponent("vab-menu");
  var _component_el_menu = _resolveComponent("el-menu");
  var _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createBlock(_component_el_scrollbar, {
    class: _normalizeClass(["vab-side-bar", {
      'is-collapse': $setup.collapse,
      'side-bar-common': $props.layout === 'common'
    }])
  }, {
    default: _withCtx(function () {
      return [$props.layout === 'vertical' || $props.layout === 'comprehensive' || $props.layout === 'float' ? (_openBlock(), _createBlock(_component_vab_logo, {
        key: 0
      })) : _createCommentVNode("v-if", true), !$setup.handleRoutes ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($setup.handleRoutes), 1 /* TEXT */)) : _createCommentVNode("v-if", true), _createVNode(_component_el_menu, {
        "active-text-color": $setup.variables['menu-color-active'],
        "background-color": $setup.variables['menu-background'],
        collapse: $setup.collapse,
        "collapse-transition": false,
        "default-active": $setup.activeMenu.data,
        "default-openeds": $setup.defaultOpeneds,
        "menu-trigger": "click",
        mode: "vertical",
        "text-color": $setup.variables['menu-color'],
        "unique-opened": $setup.uniqueOpened
      }, {
        default: _withCtx(function () {
          return [_createCommentVNode(" <el-sub-menu index=\"1\" v-for=\"(item, index) in list\" :key=\"index\">\n        <template #title>\n          <el-icon><location /></el-icon>\n          <span>Navigator One{{ item }}</span>\n        </template>\n        <el-menu-item-group title=\"Group One\">\n          <el-menu-item index=\"1-1\">item one</el-menu-item>\n          <el-menu-item index=\"1-2\">item one</el-menu-item>\n        </el-menu-item-group>\n        <el-menu-item-group title=\"Group Two\">\n          <el-menu-item index=\"1-3\">item three</el-menu-item>\n        </el-menu-item-group>\n        <el-sub-menu index=\"1-4\">\n          <template #title>item four</template>\n          <el-menu-item index=\"1-4-1\">item one</el-menu-item>\n        </el-sub-menu>\n      </el-sub-menu>\n      <el-menu-item index=\"2\">\n        <el-icon><icon-menu /></el-icon>\n        <span>Navigator Two</span>\n      </el-menu-item>\n      <el-menu-item index=\"3\" disabled>\n        <el-icon><document /></el-icon>\n        <span>Navigator Three</span>\n      </el-menu-item>\n      <el-menu-item index=\"4\">\n        <el-icon><setting /></el-icon>\n        <span>Navigator Four</span>\n      </el-menu-item> "), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.list, function (item, index) {
            return _openBlock(), _createBlock(_component_vab_menu, {
              key: index,
              item: item
            }, null, 8 /* PROPS */, ["item"]);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["active-text-color", "background-color", "collapse", "default-active", "default-openeds", "text-color", "unique-opened"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class"]);
}