import Layout from "@vab/layouts/index.vue";

export default {
  path: "/order",
  name: "order",
  component: Layout,
  meta: {
    title: "订单",
    icon: "user-settings-line",
  },
  children: [
    {
      path: "admin",
      name: "admin",
      meta: {
        title: "",
        icon: "remixicon-line",
      },

      children: [
        {
          path: "info",
          name: "info",
          component: () => import("~/src/views/order/admin/info.vue"),
          meta: {
            title: "订单详情（新）",
            icon: "flow-chart",
          },
        },
      ],
    },
  ],
};
