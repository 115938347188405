import Layout from "@vab/layouts/index.vue";

export default {
  path: "/da",
  name: "Demo",
  component: Layout,
  meta: {
    title: "测试",
    icon: "home-2-line",
  },
  children: [
    {
      path: "demo",
      name: "Demo",
      component: () => import("@/views/demo/index.vue"),
      meta: {
        title: "测试菜单",
        icon: "home-2-line",
        // noClosable: true,
      },
      children: [],
    },
  ],
};
