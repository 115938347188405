import request from "@/utils/request";

/**
 * 获取菜单列表
 * @param {*} params
 */
export function getMenuList(params?: any) {
  return request({
    url: "/v1/permission/getMenuList",
    method: "get",
    params,
  });
}
/**
 * 维护角色菜单关系
 * @param {*} params
 */
export function saveRoleMenuRel(data: any) {
  return request({
    url: "/v1/permission/saveRoleMenuRel",
    method: "post",
    data,
  });
}
/**
 * 维护角色功能权限关系
 * @param {*} params
 */
export function saveRoleFunctionPermissionRel(data: any) {
  return request({
    url: "/v1/permission/saveRoleFunctionPermissionRel",
    method: "post",
    data,
  });
}
/**
 * 维护角色数据权限关系
 * @param {*} params
 */
export function saveRoleDataPermissionRel(data: any) {
  return request({
    url: "/v1/permission/saveRoleDataPermissionRel",
    method: "post",
    data,
  });
}

/**
 * 获取新增菜单里模块的数据
 * @param {*} params
 */
export function getModule(params?: any) {
  return request({
    url: "/v1/system/getOptions",
    method: "get",
    params,
  });
}

export function createMenu(data?: any) {
  return request({
    url: "/v1/permission/createMenu",
    method: "put",
    data,
  });
}

export function updateMenu(data?: any) {
  return request({
    url: "/v1/permission/updateMenu",
    method: "post",
    data,
  });
}

export function removeMenu(params?: any) {
  return request({
    url: "/v1/permission/removeMenu",
    method: "delete",
    params,
  });
}

/**
 *
 * @param {Object} data
 * @returns 菜单移动排序
 */
export function changeTreeSort(data?: any) {
  return request({
    url: "/v1/permission/changeTreeSort",
    method: "post",
    data,
  });
}

/**
 * 获取职员拥有的菜单ID列表
 * @param {*} params
 */
export function getEmployeeMenuList(params?: any) {
  return request({
    url: "/v1/permission/getEmployeeMenuList",
    method: "get",
    params,
  });
}

/**
 * 保存职员菜单关系
 * @param {*} params
 */
export function saveEmployeeMenuRel(data: any) {
  return request({
    url: "/v1/permission/saveEmployeeMenuRel",
    method: "post",
    data,
  });
}
