import Layout from "@vab/layouts/index.vue";

export default {
  path: "/shop",
  name: "shop",
  component: Layout,
  meta: {
    title: "",
    icon: "user-settings-line",
  },
  children: [
    {
      path: "message",
      name: "message",
      meta: {
        title: "通知",
        icon: "remixicon-line",
      },

      children: [
        {
          path: "user",
          name: "user",
          component: () => import("~/src/views/shop/message/user/index.vue"),
          meta: {
            title: "我的消息",
            icon: "flow-chart",
          },
        },
      ],
    },
  ],
};
