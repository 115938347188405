import Layout from "@vab/layouts/index.vue";

export default {
  path: "/marketing",
  name: "Marketing",
  component: Layout,
  meta: {
    title: "营销",
    icon: "home-2-line",
  },
  children: [
    {
      path: "coupon",
      name: "coupon",
      meta: {
        title: "优惠券",
        icon: "remixicon-line",
      },
      children: [
        {
          path: "list",
          name: "list",
          component: () => import("@/views/marketing/coupon/list/index.vue"),
          meta: {
            title: "优惠券",
            icon: "home-2-line",
          },
        },
      ],
    },
    {
      path: "classify",
      name: "classify",
      component: () => import("@/views/marketing/classify/index.vue"),
      meta: {
        title: "营销配置",
        icon: "home-2-line",
      },
    },
    {
      path: "setMeal/index",
      name: "setMeal/index",
      component: () => import("@/views/marketing/setMeal/index.vue"),
      meta: {
        title: "套餐搭配",
        icon: "home-2-line",
      },
    },

    {
      path: "qualityTesting/index",
      name: "qualityTesting/index",
      component: () => import("@/views/marketing/qualityTesting/index.vue"),
      meta: {
        title: "订单规则",
        icon: "home-2-line",
      },
    },
    {
      path: "marketing/promotion",
      name: "marketing/promotion",
      component: () => import("@/views/marketing/promotion/index.vue"),
      meta: {
        title: "订单促销",
        icon: "home-2-line",
      },
    },
    {
      path: "suit/index",
      name: "suit/index",
      component: () => import("@/views/marketing/suit/index.vue"),
      meta: {
        title: "超值礼包",
        icon: "home-2-line",
      },
    },
    {
      path: "marketing/discount",
      name: "marketing/discount",
      component: () => import("@/views/marketing/discount/index.vue"),
      meta: {
        title: "商品折扣",
        icon: "home-2-line",
      },
    },
    {
      path: "luckDraw/index",
      name: "luckDraw/index",
      component: () => import("@/views/marketing/luckDraw/index.vue"),
      meta: {
        title: "抽奖活动",
        icon: "home-2-line",
      },
    },
    {
      path: "configuration/index",
      name: "configuration/index",
      component: () => import("@/views/marketing/configuration/index.vue"),
      meta: {
        title: "抽奖配置",
        icon: "home-2-line",
      },
    },
  ],
};
