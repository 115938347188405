import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_icon = _resolveComponent("vab-icon");
  var _component_el_badge = _resolveComponent("el-badge");
  return _openBlock(), _createBlock(_component_el_badge, {
    type: "danger",
    value: 10
  }, {
    default: _withCtx(function () {
      return [_createCommentVNode(" <el-popover placement=\"bottom\" trigger=\"hover\" :width=\"300\"> "), _createCommentVNode(" <template #reference> "), _createVNode(_component_vab_icon, {
        icon: "notification-line"
      }), _createCommentVNode(" </template> "), _createCommentVNode(" <el-tabs v-model=\"activeName\" @tab-click=\"handleClick\">\n        <el-tab-pane :label=\"translate('通知')\" name=\"notice\">\n          <div class=\"notice-list\">\n            <el-scrollbar>\n              <ul>\n                <li v-for=\"(item, index) in notices\" :key=\"index\">\n                  <el-avatar :size=\"45\" :src=\"item.image\" />\n                  <span v-html=\"item.notice\" />\n                </li>\n              </ul>\n            </el-scrollbar>\n          </div>\n        </el-tab-pane>\n        <el-tab-pane :label=\"translate('邮件')\" name=\"email\">\n          <div class=\"notice-list\">\n            <el-scrollbar>\n              <ul>\n                <li v-for=\"(item, index) in notices\" :key=\"index\">\n                  <el-avatar :size=\"45\" :src=\"item.image\" />\n                  <span>{{ item.email }}</span>\n                </li>\n              </ul>\n            </el-scrollbar>\n          </div>\n        </el-tab-pane>\n      </el-tabs>\n      <div class=\"notice-clear\" @click=\"handleClearNotice\">\n        <el-button text type=\"primary\">\n          <vab-icon icon=\"close-circle-line\" />\n          <span>{{ translate(\"清空消息\") }}</span>\n        </el-button>\n      </div> "), _createCommentVNode(" </el-popover> ")];
    }),
    _: 1 /* STABLE */
  });
}