import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_icon = _resolveComponent("vab-icon");
  var _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  var _component_el_dropdown = _resolveComponent("el-dropdown");
  return $setup.theme.showLanguage ? (_openBlock(), _createBlock(_component_el_dropdown, {
    key: 0,
    onCommand: $setup.handleCommand
  }, {
    dropdown: _withCtx(function () {
      return [_createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_dropdown_item, {
            command: "zh"
          }, {
            default: _withCtx(function () {
              return [_createTextVNode("中文简体")];
            }),
            _: 1 /* STABLE */
          }), _createVNode(_component_el_dropdown_item, {
            command: "en"
          }, {
            default: _withCtx(function () {
              return [_createTextVNode("English")];
            }),
            _: 1 /* STABLE */
          })];
        }),
        _: 1 /* STABLE */
      })];
    }),
    default: _withCtx(function () {
      return [_createVNode(_component_vab_icon, {
        icon: "translate"
      })];
    }),
    _: 1 /* STABLE */
  })) : _createCommentVNode("v-if", true);
}