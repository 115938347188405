import { useSettingsStore } from "@/store/modules/settings";
import { storeToRefs } from 'pinia';

import { defineComponent, ref, watch, onUnmounted } from 'vue';
var imports = require.context("./", true, /\.vue$/);
var Components = {};
imports.keys().filter(function (key) {
  return key !== "./index.vue";
}).forEach(function (key) {
  Components[key.replace(/(\/|\.|index.vue)/g, "")] = imports(key).default;
});
export default defineComponent({
  name: "Layouts",
  components: Components,
  setup: function () {
    var settingsStore = useSettingsStore();
    var _a = storeToRefs(settingsStore),
      device = _a.device,
      collapse = _a.collapse,
      theme = _a.theme;
    var toggleDevice = settingsStore.toggleDevice,
      foldSideBar = settingsStore.foldSideBar,
      openSideBar = settingsStore.openSideBar,
      updateTheme = settingsStore.updateTheme;
    var mobile = ref(false);
    var oldLayout = theme.value.layout;
    var resizeBody = function () {
      mobile.value = document.body.getBoundingClientRect().width - 1 < 992;
    };
    watch(mobile, function (val) {
      if (val) {
        oldLayout = theme.value.layout;
        foldSideBar();
      } else {
        openSideBar();
      }
      theme.value.layout = val ? "vertical" : oldLayout;
      toggleDevice(val ? "mobile" : "desktop");
    });
    resizeBody();
    updateTheme();
    window.addEventListener("resize", resizeBody);
    onUnmounted(function () {
      if (mobile) theme.value.layout = oldLayout;
      window.removeEventListener("resize", resizeBody);
    });
    return {
      theme: theme,
      device: device,
      mobile: mobile,
      collapse: collapse,
      foldSideBar: foldSideBar,
      openSideBar: openSideBar,
      toggleDevice: toggleDevice
    };
  }
});